import Vue from 'vue';
import Vuex from 'vuex';
import i18n from '../i18n';
import router from '../router';
import localforage from 'localforage';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        app_version: process.env.VUE_APP_VERSION,
        api_url: process.env.VUE_APP_API_URL,
        api_domain: process.env.VUE_APP_API_DOMAIN,

        // api response notification
        show_response: false,
        response: null,
        response_type: 'success',
        response_timeout: 0,

        // stores
        app_store: null,

        // login associated
        access_token: null,

        // user associated
        user_details: null,
        permissions: null,
    },
    mutations: {
        mutateShowResponse(state, payload) {
            state.show_response = payload;
        },
        mutateResponse(state, payload) {
            state.response = payload;
        },
        mutateResponseType(state, payload) {
            state.response_type = payload;
        },
        mutateResponseTimeout(state, payload) {
            state.response_timeout = payload;
        },
        mutateAppStore(state, payload) {
            state.app_store = payload;
        },
        mutateAccessToken(state, payload) {
            state.access_token = payload;
        },

        // user associated
        mutateUserDetails(state, payload) {
            state.user_details = payload;
        },
        mutatePermissions(state, payload) {
            state.permissions = payload;
        }
    },
    actions: {
        // make the initial set up of the App based on app locally saved data
        async initialSetUp({commit, state, getters, dispatch}) {

            // set axios headers required for api requests
            await dispatch('setHeaders');

            /*-------------------------*\
            * APP STORE
            \*-------------------------*/

            // set local forage store where is stored app data, also if creates local forage db if not exists
            await commit('mutateAppStore', await localforage.createInstance({storeName: 'app'}));

            // get access_token
            await state.app_store.getItem('access_token').then(async value => {
                await commit('mutateAccessToken', value);
                await dispatch('setAuthHeader', value);
            });

            // if user is authenticated and passcode saved
            if (getters.isLoggedIn) {

                // get app permissions
                await dispatch('getAPIPermissions');

                // get user details
                await dispatch('getAPIUserDetails');
            }
        },
        // set axios request headers
        setHeaders() {
            if (!axios.defaults.headers.common['Content-Type']) {
                axios.defaults.headers.common['Content-Type'] = `application/json`;
            }

            if (!axios.defaults.headers.common['Accept']) {
                axios.defaults.headers.common['Accept'] = `application/json`;
            }

            if (!axios.defaults.headers.common['X-Requested-With']) {
                axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
            }

            if (!axios.defaults.headers.common['Locale']) {
                axios.defaults.headers.common['Locale'] = i18n.locale;
            }

            if (!axios.defaults.headers.common['Content-Encoding']) {
                axios.defaults.headers.common['Content-Encoding'] = 'gzip';
            }
        },
        // set axios authorization headers
        setAuthHeader({state}, data) {
            axios.defaults.headers.common['Authorization'] = data ? `Bearer ${state.access_token}` : '';
        },

        /**
         * App reset
         */
        // reset indexedDB data
        async resetData({state, dispatch}) {

            dispatch('showResponseMessage', {
                response_type: 'warning', response: i18n.t('auth.data_reset'), response_timeout: 3000
            });

            // clear local data
            await state.app_store.clear();

            // reset store state and set initial data from start
            await dispatch('resetState');
            await dispatch('initialSetUp');
        },
        // reset state data used in authentication
        async resetState({commit, state, dispatch}) {
            await commit('mutateAccessToken', null);
            await dispatch('setAuthHeader', null);
        },

        /**
         * General actions
         */
        // get permission name and return if user has the given permission
        hasPermission({state}, permission_name) {
            // get array with all user roles
            let roles = state.user_details.roles.map(obj => obj.name);

            // get permission object from permissions array
            let permission = state.permissions.find(obj => obj.name === permission_name);

            return permission.roles.find(obj => roles.indexOf(obj.name) > -1) !== undefined;
        },
        // get response from API call and show the appropriate notification
        async showResponseMessage({commit, dispatch}, data) {

            let response = [];
            commit('mutateResponse', null);

            // Unauthorized request
            if (data.response.response === undefined && data.response.request !== undefined
                && data.response.request.readyState === 4 && data.response.request.status === 0) {
                await dispatch('logoutProcess');
                return;
            }

            // custom message or warning
            if (['custom_message'].indexOf(data.response_origin) > -1 || data.response_type === 'warning') {
                response = [data.response];

                // internal server error
            } else if (data.response.response === undefined && data.response_type === 'error') {
                response = [i18n.t('general.something_went_wrong')];

                // successful API response
            } else if (data.response_type === 'success') {
                response = data.response.data.message;

                // error API response
            } else if (data.response_type === 'error') {
                response = data.response.response.data.errors
            }

            commit('mutateShowResponse', true);
            commit('mutateResponseType', data.response_type);
            commit('mutateResponseTimeout', data.response_timeout);
            commit('mutateResponse', response);
        },

        /**
         * Authentication related actions
         */
        // reset data necessary to logout user
        logoutProcess({commit, state, dispatch}) {

            // delete access_token
            state.app_store.removeItem('access_token')
                .then(async () => {
                    await commit('mutateAccessToken', null);
                    await dispatch('setAuthHeader', null);

                    router.push('/login');
                });
        },

        // make API request
        async apiRequest({state, dispatch}, data) {

            let response_data = [];

            if (data.upload_files) {
                axios.defaults.headers.common['Content-Type'] = `multipart/form-data`;
            }

            await axios({
                method: data.method, url: `${state.api_url}/${data.url}`, data: data.data, params: data.params
            }).then(response => {

                if (data.method !== 'get' && !data.hide_response) {
                    dispatch('showResponseMessage', {
                        response_type: 'success', response: response, response_timeout: 3000
                    });
                }

                response_data = response.data;

            }).catch(error => {
                dispatch('showResponseMessage', {response_type: 'error', response: error, response_timeout: 0});
                response_data = error;
            });

            axios.defaults.headers.common['Content-Type'] = `application/json`;
            return response_data;
        },
        // make API call to get app permissions
        async getAPIPermissions({commit, state, dispatch}) {
            let response = await dispatch('apiRequest', {method: 'get', url: 'settings/permissions'});

            // successful response
            if (!response.response) {
                await commit('mutatePermissions', response.data);
            }
        },
        // make API call to get user details
        async getAPIUserDetails({commit, state, dispatch}) {
            let response = await dispatch('apiRequest', {method: 'get', url: 'users/profile/details'});

            // successful response
            if (!response.response) {
                await commit('mutateUserDetails', response.data);
            }
        },
        // make call to get API file
        async getAPIFile({state, dispatch}, data) {
            let file = null;

            await axios.get(`${state.api_url}/${data.url}`, {responseType: 'arraybuffer'})
                .then(response => {

                    file = new File([response.data], data.file.filename, {
                        type: response.headers['content-type'],
                        lastModified: response.headers['last-modified'],
                        id: data.file.id
                    });
                })
                .catch(error => {
                    dispatch('showResponseMessage', {response_type: 'error', response: error, response_timeout: 0});
                });

            return file;
        }
    },
    getters: {
        isLoggedIn: state => {
            return !!state.access_token;
        },
        showContent: state => {
            return !!state.permissions && !!state.user_details;
        },
        canViewIncident: state =>{

        }
    }
})

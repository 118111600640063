import Vue from 'vue';
import './plugins/axios';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import './registerServiceWorker';

import './styles/custom_style.scss';
import Auth from './mixins/auth';
import Helper from './mixins/helper';
import './registerServiceWorker';

Vue.config.productionTip = false;
Vue.prototype.$Auth = Auth;
Vue.prototype.$Helper = Helper;

// make app initial set up
store.dispatch('initialSetUp').then(() => {

    // create vue instance
    new Vue({
        router,
        store,
        vuetify,
        i18n,
        render: h => h(App)
    }).$mount('#app');

}).catch(error => {
    console.log('main.js initialSetUp error: ' + error);
});